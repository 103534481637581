// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-js": () => import("../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirmation-js": () => import("../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-cookie-js": () => import("../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-faq-js": () => import("../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hemset-js": () => import("../src/pages/hemset.js" /* webpackChunkName: "component---src-pages-hemset-js" */),
  "component---src-pages-how-js": () => import("../src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-blog-js": () => import("../src/pages/previewBlog.js" /* webpackChunkName: "component---src-pages-preview-blog-js" */),
  "component---src-pages-privacypolicy-js": () => import("../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-sustainability-js": () => import("../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-js": () => import("../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-what-js": () => import("../src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */)
}

